// $mobile: 600px;
//
// @media (max-width: $mobile) {
//   div {
//     background: red;
//   }
//   /* .App {
//     background: red;
//   } */
// }

.App {
  text-align: center;
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-size: calc(10px + 2vmin);
  color: white;
  padding: 36px;
  max-width: 1500px;
  position: relative;
}

.RecordButton {
  position: fixed;
  right: 60px;
  top: 85vh;
  z-index: 1;
  // height: 100vh;
  // width: 100vh;
}

.animation-bounce {
  animation: bounce .6s ease infinite alternate;
  -webkit-animation: bounce .6s ease infinite alternate;
  -ms-animation: bounce .6s ease infinite alternate;
}

@keyframes bounce{
  0% {transform: translateY(0px);}
  100% {transform: translateY(-10px);}
}
