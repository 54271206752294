/* Vars */

$gray-light: #7a7a7a;
$green-light: #23d160; //暫定_Bulmaの既定successの色
$time-color : #aaa;
$bg-color : #1d243e;

$width-side: 320px;
$width-side-nav: 4rem;
$height-comment-submit: 52px;


/* Bulma Override */

.navbar-link.is-active, .navbar-link:hover, a.navbar-item.is-active, a.navbar-item:hover{
  background-color: transparent;
  color: darken($green-light, 5);
}

.title, .subtitle{
  line-height: 1.2;
}

a{
  color: $gray-light;
  &:hover, &:active, &:focus{
    color: #000;
  }
}
a.is-text:not(.button):hover{
  text-decoration: underline;
}

.section{
  padding-top: 2em;
  padding-bottom: 2em;
}

.section-wide{
  padding: 5rem 1.5rem;
}

.card img{
  object-fit: cover;
}

.navbar-dropdown{
  border-radius: 0;
}

.navbar-item img{
  max-height: none;
}

input[type="checkbox"]{
  margin-right: .2rem;
  margin-left: .2rem;
}

.button.is-text{
  text-decoration: none;
  &:hover{
    text-decoration: underline;
  }
}

@media screen and (max-width: 1023px){
  .navbar-menu.is-active{
    position: absolute;
    width: 100%;
  }
}

.media-left{
  margin-right: .75rem;
}
.modal{
  z-index: 1000;
}
.modal-card-title{
  text-align: center;
}
.modal-card-body .section{
  padding-bottom: 0;
}
.modal-card-foot{
  text-align: center;
  justify-content: center;
}


/* Footer */

.content-wrapper {
  min-height: calc(100% - 52px); // ヘッダーの高さを減らす
}
.content-insede {
  padding-bottom: 90px;
}

.footer{
  height: 90px;
  margin-top: -90px;
  width: 100%;
  padding: 0;
  .footer-content{
    padding: 1rem 0;
    background-color: #fafafa;
  }
  p{
    margin-top: 1rem;
  }
  .footer-nav .is-inline-block{
    margin: 0 .75rem;
    font-size: .75rem;
    opacity: 0.4;
  }
}

@media screen and (min-width: 769px), print{
  .modal-card, .modal-content {
    width: 480px;
  }
}


/* Animation */

@keyframes disappear{
  0% {
    opacity:1;
  }
  100% {
    opacity:0;
  }
}

@keyframes pulse {
  0% {
    transform: scale(1, 1);
  }
  50% {
    opacity: 0.3;
  }
  100% {
    transform: scale(1.5);
    opacity: 0;
  }
}


/* Helper */

.is-grid {
  display: grid !important;
}

.clickable {
  &:hover{
    cursor: pointer;
  }
}

.hidden {
  display: none;
}

.invisible {
  visibility: hidden;
}

.text-right {
  text-align: right;
}

.roboto{
  font-family: roboto, sans-serif;
  font-weight: 700;
  font-style: italic;
}


/* Compornent */

.navbar-brand{
  .navbar-item{
    padding: .75rem;
  }
  img{
    width: 65px;
    /*@include media-min(mx) {
      width: 100px;
    }*/
  }
}

.icon-prof{
  border-radius: 50%;
  overflow: hidden;
  img{
    object-fit: cover;
  }
}

.dl-list{
  dt{
    font-weight: bold;
    font-size: 1.25rem;
    margin-bottom: .5rem;
  }
  dd{
    margin-bottom: 1rem;
  }
}

.tabs.is-rounded{
  ul{
    border-bottom: none;
  }
  li{
    a{
      margin-right: .5rem;
      margin-left: .5rem;
      margin-bottom: 0;
      border: none;
      background-color: transparent;
      color: $gray-light;
      &:hover{
        color: #000;
      }
    }
    &.is-active{
      a{
        background-color: hsl(0, 0%, 86%);
        color: #000;
      }
    }
  }
  @include media-max(md){
    ul{
      justify-content: center;
    }
  }
  @media screen and (max-width: 1023px){
    .icon{
      margin: 0 auto !important;
    }
  }
}


/* Dashboard */

.is-tab-text{
  @include media-max(sm) {
    display: none;
  }
}

.card-board{
  height: 100%;
  position: relative;
  padding-bottom: 3rem;

  .card-content{
    padding: .75rem;
  }

  .title{
    margin-bottom: .5rem;
  }

  .card-nav{
    position: absolute;
    top: 0;
    left: 0;
    a{
      color: #FFF;
      background-color: rgba(0, 0, 0, 0.4);
      padding: .25rem;
      &:hover{
        background-color: rgba(0, 0, 0, 0.8);
        color: rgba(255, 255, 255, 0.9);
      }
    }
  }
  .card-footer{
    position: absolute;
    width: 100%;
    bottom: 0;
  }
}


/* Board */

.page-board{
  background-color: #1d243e;
  height: 100%;
  margin: 0;
  padding: 0;
  &::-webkit-scrollbar {
    display: none;
  }
}

.board-container{
  height: 100%;
}

.board-main.expand + .board-side{
  .board-comments, .board-comments-submit {
    display: none;
  }
}

.board-main{
  position: relative;
  flex: 1;
  background-color: #1d243e;
  color: #FFF;
}

.board-main .cotoha-area{
  position: fixed;
  bottom: 15px;
  left: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;

  .cotoha-text {
    width: 100px;
    height: 25px;
  }

  .cotoha-logo {
    height: 25px;
  }
}

.board-side{
  position: fixed;
  z-index: 10;
  top: 52px;
  width: 4rem;
  @media screen and (max-width: 1023px){
    width: 100%;
  }
}

.board-main .page-header{
  position: fixed;
  width: 100%;
  z-index: 100;
  color: #FFF;
  background: linear-gradient(0deg, rgba($bg-color,0) 0%, rgba(29,36,62,1) 100%);

  a{
    color: #FFF;
  }

  .navbar{
    background: transparent;
  }

  .navbar-menu{
    font-size: initial;
  }
  .navbar-item .navbar-dropdown,
  .navbar-link.is-active, .navbar-link:hover{
    background-color: rgba(0, 0, 0, 0.9);
  }
  a.navbar-item.is-logo:hover, a.navbar-item.is-active, a.navbar-item:hover{
    color: $green-light;
    background-color: transparent;
  }

  @media screen and (max-width: 1023px){
    .navbar-menu.is-active{
      top: 0;
    }
    .navbar-burger.is-active{
      position: absolute;
      right: 0;
      z-index: 100;
    }
    .navbar-item .navbar-dropdown{
      border-top-color: hsl(0, 0%, 29%);
      background-color: transparent;
    }
    .navbar-menu{
      background: rgba(0, 0, 0, 0.9);
    }
  }
}

.board-main main{
  article{
    padding: 50px 20px;
    @include media-min(md) {
    padding: 50px 30px;
    }
    @include media-min(mx) {
      padding: 50px 50px;
    }
    time{
      display: block;
      font-size: 0.75rem;
      color: $time-color ;
      text-align: center;
      margin: 15px 0;
      @include media-min(md) {
        padding-top: 10px;
        text-align: left;

        &:before{
          content:"";
          display: inline-block;
          height: 0.2rem;
          width: 75px;
          border-top: 1px solid #707070 ;
          margin-right: 15px;
        }
      }
    }

    section{
      position: relative;
      margin-bottom: 20px;
      box-sizing: border-box;

      &.empty{
        padding: 0;
        margin: 0;
      }

      &:first-child{
        padding-top: 0 !important;
        margin-bottom: 0 !important;
      }

      @include media-min(md) {
        margin-bottom: 50px;
        justify-content: center;
      }

      @include media-min(mx) {
        //padding-top: 80px;
        //margin-bottom: 120px;
        justify-content: center;
      }

      .text-block{
        margin : 15px 0;
        padding: 18px;
        background: #fff;
        color: #000;
        transition: all linear 0.1s;

        &.deactive{
          height: 0;
          padding-top: 0;
          padding-bottom: 0;
          overflow: hidden;
          margin: 0;
          @include media-min(md) {
            opacity:0;
          }
        }

        @include media-min(md) {
          background: none;
          width: 63%;
          z-index: 10;
          left: 37%;
          position: relative;
        }

        @include media-min(mx) {
          right: 50px;
        }

        p{
          padding: 0;
          font-size: 1rem;
          margin: 0;
          line-height: 1.5rem;
          color: #000;
          font-weight: 500;
          @include media-min(md) {
            font-size: 1.75rem;
            line-height: 2.2rem;
            background: linear-gradient(transparent 0%, #FFF 0%);
            display: inline;
            //padding: 0.1rem 0.25rem;
          }
        }

        &.en p{
          text-transform: lowercase;
          &:first-letter{
            text-transform: uppercase;
            display: block;
          }
          @include media-min(md) {
            font-size: 1.5rem;
            line-height: 2.4rem;
            padding: 0 0.1rem;

          }
        }

        .keyword{
          background: linear-gradient(transparent 50%, #00FF0A 50%);
        }
      }

      .img-block{
        display: block;
        position: relative;
        overflow: hidden;

        @include media-min(md) {
          position: absolute;
          width: 48%;
          top: 0;
          margin-left: 0px;
          z-index: 5;

        }
        figure{
          margin: 0;
          max-height: 60vh;
          @include media-min(md) {
            max-height: 400px;
          }
          @include media-min(lg) {
            max-height: 600px;
          }
          overflow: hidden;
          &::after {
            content : "";
            position: absolute;
            background-color:$bg-color;
            top:-1%;
            left:-1%;
            display: block;
            width: 102%;
            height: 102%;
            transition-property : width, transform left;
            transition-timing-function : cubic-bezier(0.28,0.11,0.32,1);
            transition-delay : 0.2s;
            transform-origin : top right;
            transition-duration: 0.8s;
          }

          &.append{
            &::after {
              transform : skewX(-20deg);
              width: 0;
              left: 125%;
            }
          }
          img{
            width: 100%;
            max-width: 100%;
            object-fit: cover;
            @include media-min(md) {
              max-width: 550px;
              max-height: 500px;
            }
            @include media-min(mx) {
              max-width: 850px;
              max-height: 800px;
            }
          }
        }
      }
    }
  }
}

.paragraph{
  position: relative;

  &:nth-child(odd){
    .text-block{
      @include media-min(md) {
        left:0;
      }
    }

    .img-block{
      @include media-min(md) {
        right: 0px;
      }
    }
  }

  @media screen and (max-width: 1023px){
    &:last-child{
      padding-bottom: 60px;
    }
  }
}

.paragraph-menu{
  margin-left: 18px;
  margin-bottom: -1.6rem;
  .button{
    width: 2.4em;
    height: 2.4em;
    &:hover, &:active, &:focus{
      color: #000 !important;
    }
  }
  @include media-max(md) {
    margin-bottom: -.5rem;
    text-align: left !important;
  }
}
.paragraph:nth-child(even){
  .paragraph-menu{
    text-align: right;
    margin-right: 18px;
    @include media-min(md) {
      .dropdown-menu{
        left: auto;
        right: 0;
      }
    }
  }
}

.board-comments{
  position: relative;
  li{
    padding: .5rem;
    color: #FFF;
    strong{
      color: hsl(0, 0%, 71%);
    }
  }
}
.board-comments-submit{
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  padding: .5rem;
  .media{
    align-items: center;
  }
  .textarea{
    word-wrap: break-word;
    word-break: break-all;
    height: auto;
    padding-bottom: calc(.375em - 1px);
    padding-left: calc(.625em - 1px);
    padding-right: calc(.625em - 1px);
    padding-top: calc(.375em - 1px);
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    &:not([rows]){
      min-height: 2em;
    }
    &:focus{
      border-color: transparent;
    }
  }
  [data-placeholder]:empty:before {
    content: attr(data-placeholder);
    color: hsl(0, 0%, 71%);
  }
  .button{
    height: 100%;
  }
}

.nav-vertical{
  bottom: 0;
  background-color: #FFF;
  z-index: 10;
  width: 100%;
  .menu-list li{
    border-right: 1px solid #e4e4e4;
    flex:1;
    &:last-child{
      border-right: none;
    }
  }
  .nav-status{
    margin-top: .25rem;
  }
  .dropdown-menu{
    .buttons.has-addons{
      .button{
        margin-top: -.5rem;
        border-top: none;
        border-bottom: none;
        margin-bottom: 0;
        &:first-child{
          border-left: none;
          border-radius: 0 0 0 4px;
        }
        &:last-child{
          border-right: none;
          border-radius: 0 0 4px 0;
        }
      }
    }
  }
  .menu-list a{
    transition: .3s background-color;
  }
  .menu-list a:hover, .dropdown.is-active{
    .icon-has-bg{
      background-color: $green-light;
      color: #FFF;
    }
  }
}

.button-record{
  .nav-status::after{
    content: "OFF";
  }
  &.active .nav-status::after{
    content: "ON";
  }

  .icon-has-bg{
    background-color: #3f51b5;
    color: #FFF;
  }
  &.active{
    position: relative;
    z-index: auto;
    transform: translateZ(0);
    .icon-has-bg{
      background-color: #f50057;
      color: #FFF;
      &::before{
        content: "";
        z-index: -1;
        height: 2em;
        width: 2em;
        border-radius: 50%;
        position: absolute;
        display: inline-block;
        background-color: #f50057;
        transform: translateZ(0);
        animation: pulse 1.2s ease infinite;
      }
    }
  }
}

.button-images{
  .nav-status::after{
    content: attr(data-images);
  }
}

.button-comment{
  .nav-status::after{
    content: "OFF";
  }
  &.active .nav-status::after{
    content: "ON";
  }
}

.button-clip{
  .nav-status::after{
    content: "PIN";
  }
  &.active .nav-status::after{
    content: "PINED";
  }
}

.img-block .is-grid {
  gap: 10px;
  height: 200px;
  .item img {
    height: 100%;
    background-color: #fff;
    &.noimg{
      background-color: transparent;
      width: 100%;
      object-fit: contain;
    }
  }
  @include media-min(sm) {
    height: 300px;
  }
}

.board-images-1 .item:nth-child(n + 2){
  display: none;
}
.board-images-2{
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  .item:nth-child(n + 3){
    display: none;
  }
}
.board-images-3{
  grid-template-columns: 2fr 1fr;
  grid-template-rows: 1fr 1fr;
  .item:nth-child(n + 4){
    display: none;
  }
  .item:first-child{
    grid-row: 1 / 3;
  }
}
.board-images-4{
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  .item:nth-child(n + 5){
    display: none;
  }
}
.board-images-5{
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  .item:first-child{
    grid-column: 1 / 3;
  }
  .item:nth-child(n + 6){
    display: none;
  }
}
.board-images-6{
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  .item:nth-child(n + 7){
    display: none;
  }
}

.button-text-edit{
  width: 2.4em;
  height: 2.4em;
  padding: 0 !important;
  vertical-align: text-bottom;
  @include media-min(md) {
    margin-left: .5rem;
  }
}

.icon-has-bg{
  width: 2em;
  height: 2em;
  text-align: center;
  background-color: #ebebeb;
  border-radius: 50%;
  line-height: 1;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: none;
}
.active .icon-has-bg{
  background-color: #000;
  color: #FFF;
}

#ModalUrl{
  .modal-card{
    width: 94%;
    max-width: 640px;
  }
  p.text-url{
    margin: .5rem 2px .5rem 0;
  }
  h4.text-url{
    word-break: break-all;
    margin-bottom: .5rem;
    font-size: 1.25rem;
    @media screen and (max-width: 575.98px){
      font-size: .75rem;
      margin-left: -15px;
      margin-right: -15px;
    }
    @media screen and (max-width: 375px){
      font-size: 10px;
    }
  }
  [class*="is-flex-"]{
    align-items: center;
  }
}


/* Board Touch Layout */

@media screen and (max-width: 1023px){
  .board-comments{
    overflow: hidden;
    transform: rotate(180deg);
    li{
      display: none;
      transform: rotate(-180deg);
      background-color: rgba(0, 0, 0, 0.9);
    }
    li.added{
      display: block;
      animation: disappear ease 2s;
      animation-delay: 2s;
      animation-fill-mode: forwards;
      animation-timing-function: linear;
      &:nth-last-child(n+4){
        display: none !important;
      }
    }
  }
  .is-mobile-bottom-nav{
    position: fixed;
    bottom: 0;
    width: 100%;

    .cotoha-area {
      color: #fff;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding-left: 10px;
      padding-bottom: 5px;

      @media screen and (max-width: 575.98px){
        font-size: 12px;
      }
      .cotoha-text {
        margin-right: 10px;

        @media screen and (max-width: 575.98px){
          margin-right: 5px;
        }
      }
      .cotoha-logo {
        height: 20px;

        @media screen and (max-width: 575.98px){
          height: 15px;
        }
      }

      @media screen and (min-width: 1024px){
        display: none;
      }
    }
  }
}

/* Board Desktop Layout */

@media screen and (min-width: 1024px){
  .board-main{
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    overflow-scrolling: touch;
    &::-webkit-scrollbar {
      display: none;
    }

    .page-header{
      width: calc(100% - #{$width-side});
      transition: 0.5s width;
      padding-right: .5rem;
    }
  }
  .board-main.expand{
    padding-right: $width-side-nav;
    .page-header{
      width: calc(100% - #{$width-side-nav});
    }
    & + .board-side{
      margin-right: -$width-side;
    }
  }

  .board-side{
    width: $width-side;
    position: relative;
    top: 0;
    height: 100%;
    min-width: 4rem;
    background-color: rgba(0, 0, 0, 0.4);
    transition: 0.5s margin;
  }

  .board-comments{
    height: calc(100vh - #{$height-comment-submit});
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    overflow-scrolling: touch;
    margin-right: 4rem;
    margin-bottom: 0;
    &::-webkit-scrollbar{
      display: none;
    }
  }
  .board-comments-submit{
    min-height: $height-comment-submit;
    width: calc(100% - 4rem);
    position: absolute;
    bottom: 0;
  }
  .nav-vertical{
    position: fixed;
    width: 4rem;
    height: 100vh;
    right: 0;
    background-color: #FFF;
    z-index: 10;
    top: 0;
    .menu-list li{
      border-right: none;
      border-bottom: 1px solid #e4e4e4;
    }
    .dropdown-menu{
      top: 0 !important;
      left: auto;
      right: $width-side-nav;
    }
  }
}

.nav-text-edit{
  justify-content: flex-end;
  .button.is-text{
    color: #FFF;
    &:hover, &:focus, &:active{
      background-color: transparent;
    }
  }
}
.title-edit .nav-text-edit{
  @include media-min(md) {
    float: right;
  }
}
.paragraph .nav-text-edit .is-text{
  @include media-max(md) {
    color: #000;
  }
}


/* Top Page */

.hero.hero-top{
  background-color: $bg-color;
}
.logo-large img{
  width: 48%;
}

// for IE11 BugFix
_:-ms-lang(x)::-ms-backdrop, #topSs{
  width: auto;
  height: 320px;
}

#button-demo{
  background-color: #3f4f9a;
  margin-top: 1rem;
  transition: .4s background-color;
  &:hover, &:active, &:focus{
    background-color: lighten(#3f4f9a, 8);
  }
  span:last-child{
    display: inline-block;
    margin-left: 1rem;
  }
  @include media-max(sm) {
    display: block;
    white-space: normal;
    height: auto;
    margin: 2rem auto 0;
    span:last-child{
      display: block;
      margin: 0;
    }
  }
}
.features{
  background-color: #f8f8f8;
  h2{
    margin-bottom: 3rem;
  }
  p .br{
    display: inline-block;
  }
  li p{
    color: #000;
    font-weight: 700;
    margin-bottom: 2rem;
  }
  ul{
    margin-bottom: 1rem;
  }
  .pict{
    margin-bottom: 1rem;
    color: #AEB1B0;
    img{
      height: 3rem;
    }
  }
  @include media-min(md) {
    p .br{
      display: block;
    }
  }
}

.credit{
  background-color: #e4e4e4;
  p:not(:last-child){
    margin-bottom: 2rem;
  }
  .heading{
    color: #000;
    display: inline;
    background: linear-gradient(transparent 80%,#00ff0a 0);
    font-weight: 700;
  }
  .level img{
    vertical-align: text-bottom;
  }
}
