// breakpoints
$breakpoints: (
  'sm': 420px,
  'md': 767px,
  'm': 945px,
  'lg': 1160px,
  'mx' : 1440px
) !default;
@mixin media-min($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media screen and (min-width: #{inspect(map-get($breakpoints, $breakpoint))}) {
      @content;
    }
  } @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
      + "Please make sure it is defined in `$breakpoints` map.";
  }
}
@mixin media-max($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media screen and (max-width: #{inspect(map-get($breakpoints, $breakpoint) - 1px)}) {
      @content;
    }
  } @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
      + "Please make sure it is defined in `$breakpoints` map.";
  }
}
@mixin media-between($breakpoint-min, $breakpoint-max) {
  @if map-has-key($breakpoints, $breakpoint-min) and map-has-key($breakpoints, $breakpoint-max) {
    @media screen and (min-width: #{inspect(map-get($breakpoints, $breakpoint-min))}) and (max-width: #{inspect(map-get($breakpoints, $breakpoint-max))}) {
      @content;
    }
  } @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
      + "Please make sure it is defined in `$breakpoints` map.";
  }
}

//基礎級数
@function get_vw($size, $viewport:750){
  $rate: 100 / $viewport;
  @return $rate * $size * 1vw;
}
@mixin fz($font_size:10){
  font-size: get_vw($font_size);
  @include media-min(md) {
    font-size: $font_size * 1px;
  }
}

//基礎横幅
@mixin container($width) {
  max-width: $width;
  width: 94%;
  margin: 0 auto;
  @include media-max(md) {
    width: 100%;
    padding: 0 20px;
  }
}

//基礎間隔
@mixin lm {
  margin: 40px auto;
  @include media-min(md) {
    margin: 80px auto;
  }
}
