body,html{
  height: 100%;
  margin: 0;
  padding: 0;
  // overflow-x: hidden;
  &::-webkit-scrollbar {
    display: none;
  }
}

body{
  font-family: "游ゴシック", "Yu Gothic", YuGothic, "Hiragino Sans", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  font-weight: 500;
  background-color: #f5f5f5;
}
#root{
  height: 100%;
  position: relative;
}

/*body{
  font-size: 16px;
  @include media-min(md) {
    font-size: 16px;
  }
  @include media-min(lg) {
    font-size: 20px;
  }
  @include media-min(mx) {
    font-size: 32px;
  }
}*/


.en{
  font-family: source-sans-pro, sans-serif;
  font-weight: 400;
  font-style: normal;
}